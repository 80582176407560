import {mapActions, mapGetters} from "vuex";
import en from "vue2-datepicker/locale/es/en";
import {DataService} from "@/services/DataService";
import {permissionMixin} from "@/mixins/permissionMixins";

export default {
  mixins : [permissionMixin],
  data() {
    return {
      audit_main_types : [
        // index 0 means SMS
        {
          enabled : false,
          type : 'sms'
        },
        // index 1 means ISPS
        {
          enabled : false,
          type : 'isps'
        },
        // index 2 means CYBER
        {
          enabled : false,
          type : 'cyber'
        }
      ],
      type: 'sms',
    }
  },
  methods : {
    ...mapActions([
      'getVesselDetail',
      'getVesselAuditRecordList',
      'getVesselAuditRecordById',
      'deleteAuditFile',
      'deleteAuditRecord',
      'createAuditRecord',
      'updateAuditRecord',
      'addRequestKeys',
      'generateRequestKeys',
      'getCrewKeys',
      'updateAuditSettings',
    ]),
    createFormDataParam(param, wholeObject = false) {
      let paramForm = new FormData();
      for (const key in param) {
        if (typeof param[key] === 'object' && wholeObject === true) {
          for (const key2 in param[key]) {
            const newKey = key + '[' + key2 + ']';
            const specialKeys = ['audit_type_2', 'audit_type_3', 'audit_date', 'lead_ids', 'members_ids'];

            if(specialKeys.includes(key2)) {
              for (const key3 in param[key][key2]) {
                const newKey2 = key + '[' + key2 + ']' + '[' + key3 + ']';
                paramForm.append(newKey2, param[key][key2][key3]);
              }
            } else {
              if(param[key][key2] !== null) {
                paramForm.append(newKey, param[key][key2]);
              }
            }
          }
        } else {
          if (param[key] === null || param[key] === '') {
            param[key] = '';
          } else {
            param[key] = typeof param[key] === 'string' ? param[key].trim() : param[key];
          }
          // paramForm.append(key, param[key] === null ? '' : param[key]);
        }
      }

      return paramForm;
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    getFirstLandingType(auditRecord) {
      const invalidValue = [null, undefined];

      if (invalidValue.includes(auditRecord.sms) === false) {
        return 'sms';
      }
      if (invalidValue.includes(auditRecord.isps) === false) {
        return 'isps';
      }
      return 'cyber';
    },
    getDataBasedOnActiveTypes(previousData) {
      let newObject = {};
      this.audit_main_types.forEach(auditType => {
        if (auditType.enabled === true) {
          newObject[auditType.type] = previousData[auditType.type];
        }
      });
      return newObject;
    },
    removeUnnecessaryKey(auditRecordByType, unnecessaryKeys) {
        for (const attribute in auditRecordByType) {
          if (unnecessaryKeys.includes(attribute) === true) {
            delete auditRecordByType[attribute];
          }
        }
      return auditRecordByType;
    },
    resetAuditMainTypes() {
      this.audit_main_types.forEach(type => {
        type.enabled = false;
      });
    },
    setTypeValues(data) {
      const auditTypes = DataService.assignObjectKeysToOtherObject(data, [
        'audit_type_2',
        'audit_type_3',
      ])

      const {leaders, members} = this.filterAuditorsByPosition(data?.auditors ?? []);

      return {
        report_number : data?.report_number ?? '',
        audit_type_2 : auditTypes?.audit_type_2 ?? [],
        reason_of_audit : {
          file : null,
          file_path : data?.reason_of_audit !== null ? data?.reason_of_audit_url ?? null : null,
          request_delete : false
        },
        audit_type_3 : auditTypes?.audit_type_3 ?? [],
        received_approval : data?.received_approval ?? 0,
        evidence_of_approval : {
          file : null,
          file_path : data?.evidence_of_approval !== null ? data?.evidence_of_approval_url ?? null : null,
          request_delete : false
        },
        audit_date : [
          data?.audit_date_start['date-picker'] ?? null,
          data?.audit_date_end['date-picker'] ?? null
        ],
        audit_date_human : [
          data?.audit_date_start['human'] ?? null,
          data?.audit_date_end['human'] ?? null
        ],
        appointment_date : data?.appointment_date['date-picker'] ?? null,
        appointment_date_human : data?.appointment_date['human'] ?? null,
        audit_country_id : data?.country_id ?? null,
        audit_port_id : data?.port_id ?? null,
        lead_ids : leaders.map(leader => leader.id),
        leaders : leaders,
        members_ids :members.map(member => member.id),
        members :members,
        extension : data?.is_extended ?? null,
        extension_date_human : data?.extended_date['human'] ?? null,
        extension_date : data?.extended_date['date-picker'] ?? null,
        report_status_id : data?.report_status_id,
        audit_master : data?.audit_master ?? null,
        deck_dept_head : data?.deck_dept_head ?? null,
        eng_dept_head : data?.eng_dept_head ?? null
      };
    },
    filterAuditorsByPosition(auditors) {
      let filteredAuditors = {
        leaders : [],
        members : []
      };
      if (auditors.length > 0) {
        auditors.forEach((auditor) => {
          if (auditor.pivot.is_lead === 1) {
            filteredAuditors.leaders.push(auditor);
          } else {
            filteredAuditors.members.push(auditor);
          }
        });
      }
      return filteredAuditors;
    },
    addTypeInitialValues() {
      return {
        audit_type_2 : [],
        audit_type_3 : [],
        audit_date : [null, null],
        appointment_date : null,
        audit_country_id : null,
        audit_port_id : null,
        lead_ids : [],
        members_ids : [],
        extension : 0,
        extension_date : null,
        same_in : {
          value : null,
          enabled : false
        }
      };
    },
    getIndexOfType(type) {
      if (type === 'sms') {
        return 0;
      } else  if (type === 'isps') {
        return 1;
      }
      return 2;
    },
    getActiveTypes() {
      let activeTypes = [];

      this.audit_main_types.forEach(auditType => {
        if (auditType.enabled === true) {
          activeTypes.push(auditType.type);
        }
      });
      return activeTypes;
    },
    getSameDataFromType(index, auditTypeNotNull, auditRecord, dataKey) {
        let previousType = null;
        let currentType = null;
        let previousData = null;
        let currentData = null;
        let typesHaveSameData = {};

        auditTypeNotNull.forEach((type, index) => {
          if (currentType !== null) {
            previousType = currentType;
            previousData = currentData;
          }
          currentType = auditTypeNotNull[index];

          if (dataKey === 'auditor_ids') {
            auditRecord[currentType].auditor_ids = auditRecord[currentType].auditors.map(auditor => auditor.id);
          }

          if (dataKey === 'appointment_date_picker') {
            auditRecord[currentType].appointment_date_picker = auditRecord[currentType]?.appointment_date['date-picker'] ?? null;
          }

          if (dataKey === 'audit_start_end_date') {
            auditRecord[currentType].audit_start_end_date = (auditRecord[currentType]?.audit_date_start['date-picker'] ?? '') + '-' + (auditRecord[currentType]?.audit_date_end['date-picker'] ?? '');
          }

          currentData = auditRecord[currentType][dataKey];

          if (previousType !== null && currentType !== null) {
            let newPrevData = null;
            let newCurrentData = null;
            if (Array.isArray(previousData) === true) {
              newPrevData = [];
              previousData.forEach(item => {
                newPrevData.push(item);
              })
            } else {
              newPrevData = previousData;
            }

            if (Array.isArray(currentData) === true) {
              newCurrentData = [];
              currentData.forEach(item => {
                newCurrentData.push(item);
              })
            } else {
              newCurrentData = currentData;
            }

            let prev_data = {
              data : newPrevData
            }

            let current_data = {
              data : newCurrentData
            }

            const same = DataService.checkIfChanged(prev_data, current_data) === false;

            if (same === true) {
              if (!(previousType in typesHaveSameData)) {
                typesHaveSameData[previousType] = true;
              }

              if (!(currentType in typesHaveSameData)) {
                typesHaveSameData[currentType] = false;
              }
            }
          }
        });


        return typesHaveSameData;
    },
    getAuditTypeNotNull(data) {
      let activeKeys = [];
      this.audit_main_types.forEach(auditType => {
        if (data[auditType.type] !== null) {
          activeKeys.push(auditType.type);
        }
      });
      return activeKeys;
    },
    convertAuditType2IntoType(data) {
      let types = [];
      data.forEach(type2 => {
        if (type2 === 1) {
          types.push('Annual');
        } else if (type2 === 2) {
          types.push('Occasional');
        } else {
          types.push('Navigational Audit');
        }
      });
      return types.join(' & ');
    },
    convertAuditType3IntoType(data) {
      let types = [];
      data.forEach(type2 => {
        if (type2 === 1) {
          types.push('Onsite');
        } else {
          types.push('Remote');
        }
      });
      return types.join(' & ');
    },
    getTypeThatHasAnError(ErrorKeys) {
      let type = '';
      if (ErrorKeys.length > 0) {
        let splitKey = ErrorKeys[0].split('.');
        type = splitKey[0];
      }
      return type;
    },
    filterDataById(data, dataId) {
      return data.filter(newData => {
        return newData.id === dataId
      })
      .map(item => {
        return {
          name: item.name,
          locode_wrap: item.locode_wrap ?? ''
        }
      });
    },
    async setReportTypeStatusKeys(auditRecord) {
      const NEW = 1;
      const OPEN = 2;
      const CLOSED = 3;
      const READY_TO_CLOSE = 4;
      const RECORD_ONLY = 5;
      let reportTypeStatusKeys = [];
      this.$store.commit('SET_REPORT_TYPE_STATUS_KEYS', []);
      this.reportStatusKeys.forEach((statusKey) => {
        let newStatusKey = Object.assign({}, statusKey);
        if (auditRecord[this.$route.params.type].report_status_id === NEW) {
          if (newStatusKey.id === NEW) {
            newStatusKey.visible = true;
            newStatusKey.disabled = true;
          }
          if (newStatusKey.id === READY_TO_CLOSE) {
            newStatusKey.disabled = !this.isTypeReadyToClose;
          }
          if (newStatusKey.id === CLOSED) {
            newStatusKey.visible = false;
          }
        } else if (auditRecord[this.$route.params.type].report_status_id === OPEN) {
          if (newStatusKey.id === NEW) {
            newStatusKey.visible = false;
          }

          if (newStatusKey.id === OPEN) {
            newStatusKey.visible = true;
            newStatusKey.disabled = true;
          }

          if (newStatusKey.id === READY_TO_CLOSE) {
            newStatusKey.disabled = !this.isTypeReadyToClose;
          }
          if (newStatusKey.id === CLOSED) {
            newStatusKey.visible = false;
          }
        } else if (auditRecord[this.$route.params.type].report_status_id === READY_TO_CLOSE) {
          if (newStatusKey.id === NEW) {
            newStatusKey.visible = false;
          }
          if (newStatusKey.id === READY_TO_CLOSE) {
            newStatusKey.visible = true;
            newStatusKey.disabled = true;
          }
        } else if (auditRecord[this.$route.params.type].report_status_id === CLOSED) {
          if (newStatusKey.id === NEW || newStatusKey.id === READY_TO_CLOSE) {
            newStatusKey.visible = false;
          }
          if (newStatusKey.id === OPEN) {
            newStatusKey.name = 'OPEN AGAIN';
          }

          if (newStatusKey.id === CLOSED) {
            newStatusKey.visible = true;
            newStatusKey.disabled = true;
          }
        } else {
          if (newStatusKey.id === NEW || newStatusKey.id === READY_TO_CLOSE || newStatusKey.id === CLOSED) {
            newStatusKey.visible = false;
          }
          if (newStatusKey.id === OPEN) {
            newStatusKey.name = 'OPEN AGAIN';
          }

          if (newStatusKey.id === RECORD_ONLY) {
            newStatusKey.visible = true;
            newStatusKey.disabled = true;
          }
        }
        reportTypeStatusKeys.push(newStatusKey);
        this.$store.commit('SET_REPORT_TYPE_STATUS_KEYS', reportTypeStatusKeys);
      });
    },
    convertReportStatus(reportStatusId, forList = false) {
      const statusKeys = forList === true ? this.reportStatusKeys : this.reportTypeStatusKeys;
      const filteredStatus = statusKeys.filter((reportStatus) => {
        return reportStatus.id === reportStatusId;
      });

      if (filteredStatus.length > 0) {
        return filteredStatus[0];
      }
      return this.reportStatusKeys[0] ?? [];
    },
  },
  computed : {
    ...mapGetters([
      'reportStatusKeys',
      'selectedAuditRecord',
      'vessel',
      'portKeys',
      'countryKeys',
      'vesselAuditRecord',
      'internal_audit_reports',
      'reportTypeStatusKeys',
      'director_security',
    ]),
    en() {
      return en
    },
    disabledAuditSetting() {
      return this.audit_main_types[0].enabled === false && this.audit_main_types[1].enabled === false && this.audit_main_types[2].enabled === false;
    },
    isTypeReadyToClose() {
      const invalidValue = [null, undefined];
      let extensionValidation = true;

      if (this.selectedAuditRecord[this.$route.params.type]?.extension !== null && this.selectedAuditRecord[this.$route.params.type]?.extension === 1) {
        extensionValidation = this.selectedAuditRecord[this.$route.params.type]?.extended_date.human !== null
      }

      let ca_security_director_validation = false;
      let approval_security_director_validation = false;

      if (this.$route.params.type === 'sms') {
        ca_security_director_validation = invalidValue.includes(this.internal_audit_reports[this.$route.params.type]?.ca_ship_management_director_id) === false;
        approval_security_director_validation = invalidValue.includes(this.internal_audit_reports[this.$route.params.type]?.approval_ship_management_director_id) === false;
      }

      if (this.$route.params.type === 'isps') {
        ca_security_director_validation = invalidValue.includes(this.internal_audit_reports[this.$route.params.type]?.ca_company_officer_id) === false
        approval_security_director_validation = invalidValue.includes(this.internal_audit_reports[this.$route.params.type]?.approval_company_officer_id) === false;
      }

      if (this.$route.params.type === 'cyber') {
        ca_security_director_validation = invalidValue.includes(this.internal_audit_reports[this.$route.params.type]?.ca_company_cyber_officer_id) === false
        approval_security_director_validation = invalidValue.includes(this.internal_audit_reports[this.$route.params.type]?.approval_company_cyber_officer_id) === false;
      }

      let reason_of_audit_validation = true;

      if (this.selectedAuditRecord[this.$route.params.type]?.audit_type_2.length === 1 && this.selectedAuditRecord[this.$route.params.type]?.audit_type_2[0] === 2) {
        reason_of_audit_validation = this.selectedAuditRecord[this.$route.params.type]?.reason_of_audit !== null;
      }

      let evidence_of_approval_validation = true;

      if (this.selectedAuditRecord[this.$route.params.type]?.audit_type_3.length === 1 && this.selectedAuditRecord[this.$route.params.type]?.audit_type_3[0] === 2 && this.selectedAuditRecord[this.$route.params.type]?.received_approval === 1) {
        evidence_of_approval_validation = this.selectedAuditRecord[this.$route.params.type]?.evidence_of_approval !== null;
      }

      let caDoneLaterDone = this.internal_audit_reports[this.$route.params.type]?.ca_done_later_than['date-picker'] !== null;
      let caTimeLimitDate = this.internal_audit_reports[this.$route.params.type]?.ca_time_limit_date['date-picker'] !== null;
      let caImplementedDate = this.internal_audit_reports[this.$route.params.type]?.ca_implemented_date['date-picker'] !== null;
      let caImplementedMaster = this.internal_audit_reports[this.$route.params.type]?.ca_implemented_master !== null;
      let caConfirmationDate = this.internal_audit_reports[this.$route.params.type]?.ca_confirmation_date['date-picker'] !== null;
      let caConfirmationAuditLeader = this.internal_audit_reports[this.$route.params.type]?.ca_confirmation_audit_leader !== null;

      if (this.internal_audit_reports[this.$route.params.type]?.has_ncr_obs === 0) {
        caDoneLaterDone = true;
        caTimeLimitDate = true;
        caImplementedDate = true;
        caImplementedMaster = true;
        caConfirmationDate = true;
        caConfirmationAuditLeader = true;
        ca_security_director_validation = true;
      }

      return (
        (extensionValidation) &&
        (this.selectedAuditRecord[this.$route.params.type]?.audit_type_2.length > 0) &&
        (this.selectedAuditRecord[this.$route.params.type]?.audit_type_3.length > 0) &&
        (this.selectedAuditRecord[this.$route.params.type]?.audit_date_start.human !== null && this.selectedAuditRecord[this.$route.params.type]?.audit_date_end.human !== null) &&
        (this.selectedAuditRecord[this.$route.params.type]?.appointment_date.human !== null) &&
        (this.selectedAuditRecord[this.$route.params.type]?.country_id !== null) &&
        (this.filterAuditorsByPosition(this.selectedAuditRecord[this.$route.params.type]?.auditors).leaders.length !== 0) &&
        (this.internal_audit_reports[this.$route.params.type]?.attendance_url !== null) &&
        (this.internal_audit_reports[this.$route.params.type]?.checklist_url !== null) &&
        (caDoneLaterDone) &&
        (caTimeLimitDate) &&
        (ca_security_director_validation) &&
        (caImplementedDate) &&
        (caImplementedMaster) &&
        (caConfirmationDate) &&
        (caConfirmationAuditLeader) &&
        (this.internal_audit_reports[this.$route.params.type]?.audit_approval_date['date-picker'] !== null) &&
        (approval_security_director_validation) &&
        (reason_of_audit_validation) &&
        (evidence_of_approval_validation)
      );
    },
    CaDirectorOrSecurity() {
      if (this.$route.params.type === 'sms') {
        return this.director_security.sms.filter(user => user.id === this.internal_audit_reports[this.$route.params.type]?.ca_ship_management_director_id)[0]?.name;
      }

      if (this.$route.params.type === 'isps') {
        return this.director_security.isps.filter(user => user.id === this.internal_audit_reports[this.$route.params.type]?.ca_company_officer_id)[0]?.name;
      }

      return this.director_security.cyber.filter(user => user.id === this.internal_audit_reports[this.$route.params.type]?.ca_company_cyber_officer_id)[0]?.name;
    },
    approvalDirectoryOrSecurity() {
      if (this.$route.params.type === 'sms') {
        return this.director_security.sms.filter(user => user.id === this.internal_audit_reports[this.$route.params.type]?.approval_ship_management_director_id)[0]?.name;
      }

      if (this.$route.params.type === 'isps') {
        return this.director_security.isps.filter(user => user.id === this.internal_audit_reports[this.$route.params.type]?.approval_company_officer_id)[0]?.name;
      }

      return this.director_security.cyber.filter(user => user.id === this.internal_audit_reports[this.$route.params.type]?.approval_company_cyber_officer_id)[0]?.name;
    },
  },
  async created() {
    await this.addRequestKeys([
      'getPortKeys',
      'getCountryListKeys'
    ]);
    await this.generateRequestKeys({});
  }
}
